import React, { useEffect, useState } from 'react';
import {
  Table,
  Form,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Divider,
  Button,
  Typography,
  InputNumber,
  Alert,
  message,
  Upload,
  Card,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { States, State, Institucion } from 'sigt';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Utils } from '../../../utils/validators';
import { sedematBanks } from '../../Taxes/PaySpecificTax';
const MultipleTransactions: React.FC<MultipleTransactionsProps> = ({
  banks,
  statements,
  selectedInstitution,
  form,
  data,
  maxCred = 0,
  selectedReqType,
  maxCredR = 0,
  onlyOne = false,
  addVoucher,
  removeVoucher,
}) => {
  // BOD, BANESCO Y BNC
  const [alertState, setAlertState] = useState<('success' | 'info' | 'warning' | 'error' | undefined)[]>();
  useEffect(() => {
    return () => {
      setAlertState(undefined);
    };
  }, []);

  const bankColumns: ColumnsType<any> = [
    {
      title: 'Banco',
      dataIndex: 'banco',
      key: 'banco',
      fixed: 'left',
    },
    {
      title: 'Numero de Cuenta',
      dataIndex: 'numeroCuenta',
      key: 'numeroCuenta',
    },
    {
      title: 'Titular',
      dataIndex: 'nombreTitular',
      key: 'nombreTitular',
    },
    {
      title: 'Documento de Identidad',
      dataIndex: 'documento',
      key: 'documento',
    },
  ];

  const firstListItem = (fields, add) => {
    if (fields.length === 0) {
      // setTimeout(() => add(), 50);
      add();
    }
  };

  const checkTransfer = async (number) => {
    const values = await form.getFieldsValue();
    const a = statements
      .filter((st) => st.id_banco === values.pagos[number].banco)
      .flatMap((el) => el.data)
      .find((st) => {
        const time = moment(values.pagos[number].fecha).format('YYYY-MM-DDT00:00:00');
        return (
          st.Monto === values.pagos[number].costo &&
          st.Referencia === values.pagos[number].referencia &&
          moment(st.Fecha).isSame(time)
        );
      });
    a ? message.success('La operación fue encontrada') : message.error('La operación NO fue encontrada');
    console.log(statements, values, a);
  };

  const alreadyUsedCredit = () => data.pagos && data.pagos.find((p) => p && p.metodoPago === 'CREDITO_FISCAL');
  const alreadyUsedCreditR = () => data.pagos && data.pagos.find((p) => p && p.metodoPago === 'CREDITO_FISCAL_RETENCION');

  return (
    <React.Fragment>
      <Table
        bordered
        pagination={false}
        style={{ marginBottom: 15 }}
        columns={bankColumns}
        dataSource={selectedInstitution?.cuentasBancarias?.map((c) => ({
          ...c,
          documento: `${c.rif ? 'RIF: ' : 'Cedula: '}${c.rif || c.cedula}`,
          banco: banks?.find((b) => b.id === c.banco)?.nombre,
          key: c.id,
        }))}
      />
      <Form.List name='pagos'>
        {(fields, { add, remove }) => (
          <div>
            {firstListItem(fields, add)}
            {fields.map((field, index) => (
              <React.Fragment key={field.key}>
                <Divider style={{ margin: 5, marginLeft: -5 }} orientation='left'>
                  <Typography.Text strong style={{ marginLeft: 5 }}>
                    Transacción {index + 1}
                  </Typography.Text>
                </Divider>
                <Form.Item required key={field.key} style={{ width: '100%', margin: 0 }}>
                  <Row gutter={24} style={{ padding: '0 20px' }}>
                    <Col xl={8} xs={24}>
                      <Form.Item
                        label='Método de Pago'
                        name={[field.name, 'metodoPago']}
                        rules={[{ required: true, message: 'Debe indicar el método de pago' }]}
                      >
                        <Select placeholder='Método de Pago'>
                          <Select.Option value='TRANSFERENCIA'>Transferencia</Select.Option>
                          <Select.Option value='PUNTO DE VENTA'>Punto de Venta</Select.Option>
                          <Select.Option value='DEPOSITO'>Deposito</Select.Option>
                          <Select.Option value='PAGO MOVIL'>Pago móvil</Select.Option>
                          <Select.Option value='EFECTIVO'>Efectivo Bs</Select.Option>
                          <Select.Option value='EFECTIVO PESO'>Efectivo Peso</Select.Option>
                          <Select.Option value='EFECTIVO EURO'>Efectivo Euro</Select.Option>
                          <Select.Option value='EFECTIVO DOLAR'>Efectivo Dolar</Select.Option>
                          {selectedReqType !== 'RETENCION' && maxCred && maxCred > 0 && (
                            <Select.Option disabled={alreadyUsedCredit()} value='CREDITO_FISCAL'>
                              Credito Fiscal por compesanción
                            </Select.Option>
                          )}
                          {selectedReqType !== 'RETENCION' && maxCredR && maxCredR > 0 && (
                            <Select.Option disabled={alreadyUsedCreditR()} value='CREDITO_FISCAL_RETENCION'>
                              Credito Fiscal por retención
                            </Select.Option>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                    {data.pagos &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO PESO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO EURO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO DOLAR' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' && (
                        <Col xs={24} xl={8}>
                          <Form.Item
                            label='Banco de Origen'
                            name={[field.name, 'banco']}
                            fieldKey={field.fieldKey}
                            rules={[{ required: true, message: 'Debe indicar el banco' }]}
                          >
                            {banks ? (
                              <Select placeholder='Banco de Origen'>
                                {banks?.map((b, i) => (
                                  <Select.Option key={`b-${b.id}`} value={b.id}>
                                    {b.nombre}
                                  </Select.Option>
                                ))}
                              </Select>
                            ) : (
                              <div></div>
                            )}
                          </Form.Item>
                        </Col>
                      )}
                    {data.pagos &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO PESO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO EURO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO DOLAR' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' && (
                        <Col xs={24} xl={8}>
                          <Form.Item
                            label='Banco de Destino'
                            name={[field.name, 'destino']}
                            fieldKey={field.fieldKey}
                            rules={[{ required: true, message: 'Debe indicar el banco' }]}
                          >
                            {banks ? (
                              <Select placeholder='Banco de Destino'>
                                {data.pagos[field.name]?.metodoPago === 'PUNTO DE VENTA'
                                  ? banks?.map((b, i) => (
                                      <Select.Option key={`b-${b.id}`} value={b.id}>
                                        {b.nombre}
                                      </Select.Option>
                                    ))
                                  : banks
                                      ?.filter((b) => sedematBanks.includes(b.id))
                                      .map((b, i) => (
                                        <Select.Option key={`b-${b.id}`} value={b.id}>
                                          {b.nombre}
                                        </Select.Option>
                                      ))}
                              </Select>
                            ) : (
                              <div></div>
                            )}
                          </Form.Item>
                        </Col>
                      )}

                    {data.pagos &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO PESO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO EURO' &&
                      data.pagos[field.name]?.metodoPago !== 'EFECTIVO DOLAR' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL' &&
                      data.pagos[field.name]?.metodoPago !== 'CREDITO_FISCAL_RETENCION' && (
                        <Col xs={24} xl={8}>
                          <Form.Item
                            label='Numero de Referencia'
                            name={[field.name, 'referencia']}
                            fieldKey={field.fieldKey}
                            rules={[
                              { required: true, message: 'Por favor ingrese el numero de referencia.' },
                              { validator: Utils.validators.alphaNumeric },
                            ]}
                          >
                            <Input placeholder='Referencia' />
                          </Form.Item>
                        </Col>
                      )}
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label='Fecha de Pago'
                        name={[field.name, 'fecha']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
                      >
                        <DatePicker
                          style={{ width: '100%' }}
                          format={'DD-MM-YYYY'}
                          disabledDate={(current) => current > moment().endOf('day')}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} xl={8}>
                      <Form.Item
                        label='Monto'
                        name={[field.name, 'costo']}
                        fieldKey={field.fieldKey}
                        rules={[{ required: true, message: 'Por favor ingrese el monto del pago.' }]}
                      >
                        <InputNumber
                          max={
                            data.pagos && data.pagos[field.name]?.metodoPago === 'CREDITO_FISCAL'
                              ? +(+maxCred || 0).toFixed(2)
                              : data.pagos && data.pagos[field.name]?.metodoPago === 'CREDITO_FISCAL_RETENCION'
                              ? +(+maxCredR || 0).toFixed(2)
                              : 999999999
                          }
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                          min={0}
                          step={1}
                          style={{ width: '100%' }}
                          placeholder='Monto'
                        />
                      </Form.Item>
                    </Col>
                    {data.pagos && data.pagos[field.name]?.metodoPago === 'TRANSFERENCIA' && (
                      <Col xs={24} xl={8} style={{ marginBottom: 20 }}>
                        <Button onClick={() => checkTransfer(field.name)}>Chequear Transferencia</Button>
                      </Col>
                    )}
                  </Row>
                </Form.Item>
              </React.Fragment>
            ))}
            {!onlyOne && (
              <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {fields.length > 1 && (
                  <Col>
                    <Button type='danger' onClick={() => remove(fields.length - 1)}>
                      Eliminar Transacción
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button type='dashed' onClick={() => add()}>
                    Agregar Transacción
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Form.List>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({ banks: state.bk.banks, statements: state.bk.statements });

export default connect(mapStateToProps)(MultipleTransactions);

interface MultipleTransactionsProps {
  banks: States.Banks['banks'];
  statements: States.Banks['statements'];
  selectedInstitution?: Institucion;
  form: FormInstance;
  data: any;
  maxCred?: number;
  maxCredR?: number;
  selectedReqType?: string;
  onlyOne?: boolean;
  addVoucher?: any;
  removeVoucher?: any;
}
