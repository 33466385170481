import React, { useState, useEffect, useRef } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { useWindowDimensions } from '../../../utils/hooks';
import {
  Card,
  Table,
  message,
  Divider,
  Typography,
  Tag,
  Badge,
  Button,
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  Input,
  Alert,
} from 'antd';
import axios from 'axios';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import Highlighter from 'react-highlight-words';
import { EditFilled, PlusCircleFilled, SearchOutlined, CalendarOutlined, CheckOutlined } from '@ant-design/icons';
import { sedematBanks } from '../PaySpecificTax';
import VoucherPreview from '../../Forms/components/VoucherPreview';
const server = process.env.REACT_APP_SERVER_URL;

// BOD, BANESCO Y BNC

const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

const PaymentTable = ({ dataSource, requests, setRequests }) => {
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState<any>(null);
  const [payment, setPayment] = useState<any>(null);
  const [data, setData] = useState<{ pagos: []; comprobantes: [] }>({ pagos: [], comprobantes: [] });

  const banks = useSelector((state: State) => state.bk.banks);
  const auth = useSelector((state: State) => state.auth);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();

  const canApprove = [90, 89];

  const columns: ColumnsType<any> = [
    {
      title: 'Referencia',
      dataIndex: 'referencia',
    },
    // {
    //   title: 'Banco origen',
    //   dataIndex: 'bancoOrigen',
    //   render: (id) => <span>{banks.find((b) => b.id === id)?.nombre}</span>,
    // },
    {
      title: 'Banco destino',
      dataIndex: 'bancoDestino',
      render: (id) => <span>{banks.find((b) => b.id === id)?.nombre}</span>,
    },
    {
      title: 'Fecha de Pago',
      dataIndex: 'fechaDePago',
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      render: (monto) => <span>Bs. {formatCurrency(monto)}</span>,
    },
    {
      title: 'Estado',
      dataIndex: 'aprobado',
      render: (aprobado) => (
        <span>
          <Badge status={aprobado ? 'success' : 'error'} />
          {aprobado ? 'Aprobado' : 'Por aprobar'}
        </span>
      ),
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      width: '35%',
      render: (_, record) =>
        !record.aprobado ? (
          <Row gutter={24}>
            {canApprove.includes(auth?.user?.institucion?.cargo?.id || 0) && (
              <Col>
                <Button type='primary' icon={<CheckOutlined />} onClick={() => setPayment(record)}>
                  Aprobar
                </Button>
              </Col>
            )}
            <Col>
              <Button type='primary' icon={<EditFilled />} onClick={() => setEditing(record)}>
                Editar
              </Button>
            </Col>
          </Row>
        ) : null,
    },
  ];

  useEffect(() => {
    if (!dataSource) return;
    fetchData();

    return () => {
      setData({ pagos: [], comprobantes: [] });
    };
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${server}/banks/payment/details?id=${dataSource.id}&tipoSolicitud=${dataSource.tipoSolicitud}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      const { status, pagos, comprobantes } = response.data;
      if (status !== 200) return;

      const _requests = [...requests];
      const sIndex = _requests.findIndex((r) => r.id === dataSource.id);
      _requests[sIndex].pagos = pagos ?? [];
      _requests[sIndex].comprobantes = comprobantes ?? [];
      setRequests(_requests);

      setData({ pagos, comprobantes });
    } catch (e) {
      console.log(e);
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (editing) {
      const pago = {
        ...editing,
        fechaDePago: editing?.fechaDePago ? moment(editing?.fechaDePago).utc() : null,
      };
      form.setFieldsValue(pago);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [editing]);

  const amendPayment = async (payment) => {
    const values = await form.validateFields();
    if (
      payment.monto !== values.monto ||
      payment.banco !== values.banco ||
      payment.referencia !== values.referencia ||
      payment.fechaDePago !== values.fechaDePago.toISOString()
    ) {
      setLoading(true);
      try {
        await axios.patch(
          `${server}/banks/payment/${payment.id}/`,
          {
            ...values,
            fechaDePago: values.fechaDePago.toISOString(),
            solicitud: dataSource.id,
            concepto: dataSource.tipoSolicitud,
          },
          { headers: { Authorization: `Bearer ${auth.token}` } }
        );
        message.success('Pago corregido de manera exitosa');
        const _requests = [...requests];
        const sIndex = _requests.findIndex((s) => s.id === dataSource.id);
        const _pagos = [...(_requests[sIndex]?.pagos || [])];
        const lIndex = _requests[sIndex].pagos.findIndex((l) => l.id === payment.id);
        _pagos[lIndex] = { ...payment, ...values, fechaDePago: values.fechaDePago.toISOString() };
        _requests[sIndex].pagos = _pagos;
        setRequests(_requests);
        setEditing(null);
        setData((s: any) => ({
          ...s,
          pagos: s.pagos.map((l) =>
            l.id === payment.id ? { ...l, ...values, fechaDePago: values.fechaDePago.toISOString() } : l
          ),
        }));
      } catch (e) {
        console.log(e);
        message.error(e.response?.data?.message || 'Error al corregir pago');
      } finally {
        setLoading(false);
      }
    } else {
      message.warn('Debe corregir alguno de los campos de la liquidación para poder corregirla');
    }
  };

  const approvePayment = async (payment) => {
    setLoading(true);
    try {
      await axios.put(
        `${server}/banks/validateSinglePayment`,
        { id: payment.id },
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      message.success('Pago validado de manera exitosa.');
      const _requests = [...requests];
      const sIndex = _requests.findIndex((r) => r.id === dataSource.id);
      const _pagos = [...(_requests[sIndex]?.pagos || [])];
      const lIndex = _requests[sIndex].pagos.findIndex((l) => l.id === payment.id);
      _pagos[lIndex] = { ...payment, aprobado: true };
      _requests[sIndex].pagos = _pagos;
      if (_requests[sIndex].pagos.every((p) => p.aprobado)) _requests[sIndex].estado = 'finalizado';
      setRequests(_requests);
      setPayment(null);
      setData((s: any) => ({ ...s, pagos: s.pagos.map((l) => (l.id === payment.id ? { ...l, aprobado: true } : l)) }));
    } catch (e) {
      console.log(e);
      message.error(e.response?.data?.message || 'Error al aprobar pago.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        visible={!!editing}
        onCancel={() => setEditing(null)}
        title='Corregir pago'
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <EditFilled />, title: 'Corregir', loading }}
        onOk={() => amendPayment(editing)}
      >
        <Form layout='vertical' form={form}>
          <Row gutter={24}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Fecha de Pago'
                name='fechaDePago'
                rules={[{ required: true, message: 'Debe ingresar la fecha de pago' }]}
              >
                <DatePicker placeholder='Fecha de Pago' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Referencia'
                name='referencia'
                rules={[{ required: true, message: 'Debe ingresar la referencia' }]}
              >
                <Input placeholder='Referencia' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe ingresar el banco' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())}
                  placeholder='Banco'
                >
                  {banks
                    .filter((b) => sedematBanks.includes(b.id))
                    .map((b) => (
                      <Select.Option value={b.id} key={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Monto' name='monto' rules={[{ required: true, message: 'Debe ingresar el monto' }]}>
                <Input placeholder='Monto' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={!!payment}
        onCancel={() => setPayment(null)}
        title='Aprobar pago'
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <CheckOutlined />, title: 'Corregir', loading }}
        onOk={() => approvePayment(payment)}
      >
        <Row>
          <Col span={24}>
            <Alert
              message='¿Está seguro de que desea aprobar el pago? Esta acción no puede ser reversada.'
              type='warning'
              style={{ fontWeight: 'bold' }}
            />
          </Col>
        </Row>
      </Modal>
      <Table
        columns={columns}
        dataSource={data?.pagos.length > 0 ? data.pagos : []}
        rowKey='id'
        pagination={false}
        loading={loading}
      />
      <Row style={{ marginLeft: '25px', marginTop: '25px' }}>
        <VoucherPreview comprobantes={data?.comprobantes.length > 0 ? data?.comprobantes : []} />
      </Row>
    </>
  );
};

const AmendPayments: React.FC<AmendPaymentsProps> = ({ thm, auth }) => {
  const [requests, setRequests] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(0);
  const [requesting, setRequesting] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState('J');

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColum] = useState('');
  const searchInput = useRef<any>(null);
  const [keys, setKeys] = useState<Moment[]>([]);

  const banks = useSelector((state: State) => state.bk.banks);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const tipoAddon = (
    <Select defaultValue='J' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'orange' };
    else if (estado === 'finalizado') return { name: 'Aprobado', color: 'green' };
    else return { name: 'Error', color: 'error' };
  };

  const searchByDoc = async () => {
    const values = await searchForm.validateFields();
    if (!tipoDocumento) return message.error('Debe seleccionar un tipo de documento');
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks/procedurePayments?type_doc=${tipoDocumento}&doc=${values.documento}`, {
        headers: { Authorization: `Bearer ${auth.token}` },
      });
      console.log(response.data.data);
      setRequests(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  const getColumnFilterDate = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      setKeys(selectedKeys);
      return (
        <Row style={{ width: 250, padding: 8 }} gutter={[8, 8]}>
          <Col span={24}>
            <DatePicker.RangePicker
              style={{ width: '100%' }}
              format='DD-MM-YYYY'
              placeholder={['Fecha inicio ', 'Fecha fin']}
              size='small'
              onChange={(values) => {
                setSelectedKeys(values);
              }}
              value={selectedKeys}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ width: '100%' }}
              type='primary'
              icon={<SearchOutlined />}
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Buscar
            </Button>
          </Col>
          <Col span={12}>
            <Button style={{ width: '100%' }} onClick={() => clearFilters()} size='small'>
              Restablecer
            </Button>
          </Col>
        </Row>
      );
    },
    filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => {
      return (
        moment(record[dataIndex]).utc().format('DD-MM-YYYY') === keys[0]?.utc().format('DD-MM-YYYY') ||
        moment(record[dataIndex]).utc().isBetween(keys[0]?.utc(), keys[1]?.utc(), undefined, '[]')
      );
    },
  });

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder='Buscar'
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          Buscar
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size='small' style={{ width: 90 }}>
          Restablecer
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined onClick={() => {}} style={{ color: filtered ? thm.primaryColor : undefined }} />,
    onFilter: (value, record) => record[dataIndex]?.toString()?.toLowerCase()?.includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        if (searchInput.current) {
          searchInput.current?.select();
        }
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#1e72c675', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColum(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const filters = [
    { value: 'impuesto', text: <span style={{ paddingRight: 35 }}>Impuesto</span> },
    { value: 'convenio', text: <span style={{ paddingRight: 35 }}>Convenio</span> },
    { value: 'retencion', text: <span style={{ paddingRight: 35 }}>Retención</span> },
    { value: 'tramite', text: <span style={{ paddingRight: 35 }}>Trámite</span> },
    { value: 'multa', text: <span style={{ paddingRight: 35 }}>Multa</span> },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Contribuyente',
      dataIndex: 'documento',
      ...getColumnSearchProps('documento'),
      render: (doc, record) => <span>{record?.tipoDocumento ? record.tipoDocumento + '-' + doc : doc}</span>,
    },
    {
      title: 'Fecha de la Solicitud',
      dataIndex: 'fechaSolicitud',
      ...getColumnFilterDate('fechaSolicitud'),
      render: (fecha) => <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Tipo de Solicitud',
      dataIndex: 'tipoSolicitud',
      filters,
      onFilter: (tipo, record) => record.tipoSolicitud.toLowerCase().indexOf((tipo as string).toLowerCase()) === 0,
      render: (tipo) => <b>{tipo}</b>,
    },
    {
      title: 'Estado',
      dataIndex: 'estado',
      render: (value) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(value).color}>
          {getState(value).name}
        </Tag>
      ),
    },
    {
      title: 'Monto',
      dataIndex: 'monto',
      ...getColumnSearchProps('monto'),
      sorter: (a, b) => b.monto - a.monto,
      render: (monto) => <span>Bs. {formatCurrency(parseFloat(monto))}</span>,
    },
    {
      title: 'Acciones',
      dataIndex: 'id',
      render: (id, record) =>
        record.estado !== 'tramite' ? (
          <Button type='primary' icon={<PlusCircleFilled />} onClick={() => setAdding(id)}>
            Anexar Pago
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchRequests();
    //eslint-disable-next-line
  }, []);

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${server}/banks/payment`, { headers: { Authorization: `Bearer ${auth.token}` } });
      setRequests(response.data.data);
    } catch (e) {
      message.error(e.response?.data?.message || 'Error al conseguir las solicitudes por pagar');
    } finally {
      setLoading(false);
    }
  };

  const addPayment = async (id: number) => {
    const values = await form.validateFields();
    setRequesting(true);
    try {
      const body = {
        ...values,
        fechaDePago: values.fechaDePago.toISOString(),
        id,
      };
      const response = await axios.post(`${server}/banks/payment/`, body, { headers: { Authorization: `Bearer ${auth.token}` } });
      message.success('Pago registrado de manera exitosa');
      const _requests = [...requests];
      const rIndex = _requests.findIndex((r) => r.id === id);
      _requests[rIndex].pagos = [..._requests[rIndex].pagos, { ...response.data.data[0], request: id }];
      setAdding(0);
    } catch (e) {
    } finally {
      setRequesting(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Corrección de Pagos'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 10px', color: 'white' }}
    >
      <Divider orientation='left' style={{ marginLeft: 0, marginBottom: 10, marginTop: -5 }}>
        <Typography.Title level={4}>Solicitudes Pendientes por Validación</Typography.Title>
      </Divider>
      <Form layout='vertical' style={{ marginBottom: 20 }} form={searchForm} onFinish={() => searchByDoc()}>
        <Row gutter={24}>
          <Col xs={24} xl={8}>
            <Form.Item
              name='documento'
              label='Documento de Identidad'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={3}>
            <Button
              disabled={loading}
              style={{ marginTop: width < 1200 ? 0 : 40, width: '100%' }}
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Form>
      <Table
        bordered
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <>
              <PaymentTable
                // dataSource={record.pagos.map((p) => {
                //   p.request = record.id;
                //   p.type = record.tipoSolicitud;
                //   return p;
                // })}
                dataSource={record}
                requests={requests}
                setRequests={setRequests}
              />
            </>
          ),
        }}
        dataSource={requests.map((r) => {
          r.key = r.id;
          return r;
        })}
        pagination={{ pageSize: 8 }}
      />
      <Modal
        visible={!!adding}
        title='Anexar Pago'
        onCancel={() => setAdding(0)}
        width={width < 992 ? '100%' : '40%'}
        okButtonProps={{ icon: <PlusCircleFilled />, title: 'Anexar', loading: requesting }}
        onOk={() => addPayment(adding)}
      >
        <Form layout='vertical' form={form}>
          <Row gutter={24}>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Fecha de Pago'
                name='fechaDePago'
                rules={[{ required: true, message: 'Debe ingresar la fecha de pago' }]}
              >
                <DatePicker placeholder='Fecha de Pago' style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                label='Referencia'
                name='referencia'
                rules={[{ required: true, message: 'Debe ingresar la referencia' }]}
              >
                <Input placeholder='Referencia' />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Banco' name='banco' rules={[{ required: true, message: 'Debe ingresar el banco' }]}>
                <Select
                  showSearch
                  filterOption={(value: string, option) => option?.title?.includes(value.toUpperCase())}
                  placeholder='Banco'
                >
                  {banks
                    .filter((b) => sedematBanks.includes(b.id))
                    .map((b) => (
                      <Select.Option value={b.id} key={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item label='Monto' name='monto' rules={[{ required: true, message: 'Debe ingresar el monto' }]}>
                <Input placeholder='Monto' />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({ thm: state.thm, auth: state.auth });

export default connect(mapStateToProps)(AmendPayments);

interface AmendPaymentsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
}
