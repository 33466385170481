import { Card, Col, Descriptions, Row, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { formatCurrency } from '../Taxes/PaySpecificTax';

export default function TaxpayerInfoSheet({ taxpayerInfo, loading }) {
  const branches = taxpayerInfo?.sucursales || [];

  const columns: ColumnsType<any> = [
    {
      title: 'RIM',
      align: 'center',
      dataIndex: 'referencia_municipal',
    },
    {
      title: 'Denominación comercial',
      align: 'center',
      dataIndex: 'denominacion_comercial',
    },
  ];

  const descriptionBranches = (item) => {
    return (
      item?.deudas &&
      item?.deudas?.map((d) => (
        <Descriptions key={d?.ramo_nombre_corto}>
          <Tooltip title={d?.ramo_nombre_largo}>
            <Descriptions.Item label={'Ramo'}>{d?.ramo_nombre_corto}</Descriptions.Item>
          </Tooltip>
          <Descriptions.Item label={'Deuda Total Bs'}>{formatCurrency(+(d?.monto_total ?? 0))}</Descriptions.Item>
          <Descriptions.Item label={'Deuda Total MMV'}>{formatCurrency(+(d?.monto_petro_total ?? 0))}</Descriptions.Item>
        </Descriptions>
      ))
    );
  };

  return (
    <Card>
      <Row justify='start' align='middle' gutter={[24, 24]}>
        <Col span={24}>
          <Typography.Title level={4}>Información del contribuyente</Typography.Title>
          <Descriptions>
            <Descriptions.Item label='Razón social'>{taxpayerInfo?.razon_social ?? 'NO APLICA'}</Descriptions.Item>
          </Descriptions>
        </Col>

        <Col span={24}>
          <Typography.Title level={4}>Sucursales</Typography.Title>
          <Table
            bordered
            columns={columns}
            dataSource={branches.map((b) => ({ ...b, key: b.id }))}
            pagination={{
              pageSize: 5,
            }}
            loading={loading}
            expandable={{ expandedRowRender: (record) => descriptionBranches(record) }}
          />
        </Col>
      </Row>
    </Card>
  );
}
