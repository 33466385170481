import { Form, Select } from 'antd';
import React from 'react';

export default function PhoneSelect({ name }) {
  return (
    <Form.Item noStyle name={name}>
      <Select style={{ width: 80 }}>
        <Select.Option value='424'>0424</Select.Option>
        <Select.Option value='414'>0414</Select.Option>
        <Select.Option value='412'>0412</Select.Option>
        <Select.Option value='426'>0426</Select.Option>
        <Select.Option value='416'>0416</Select.Option>
      </Select>
    </Form.Item>
  );
}
