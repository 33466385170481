import { message } from 'antd';
import axios from 'axios';
import moment from 'moment';
const server = process.env.REACT_APP_BANK_API_URL;
export const BANCAMIGA_ID = 5;

export const findPaymentMobile = async (data: findPaymentMobileDto) => {
  try {
    const response = await axios.post(`${server}/bancamiga/findPaymentMobile`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findInstantCreditPayments = async (date: string) => {
  try {
    const response = await axios.post(`${server}/bancamiga/findInstantCreditPayments`, { date });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findInternalInstantCreditPayments = async () => {
  try {
    const response = await axios.get(`${server}/bancamiga/findInternalInstantCreditPayments`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkPaymentBancamiga = async ({ field = 0, values, bankCode = '0' }) => {
  try {
    const originBank = values.pagos[field].banco;
    const paymentMethod = values.pagos[field].metodoPago;
    const referencia = values.pagos[field].referencia;
    const date = moment(values.pagos[field].fecha).format('YYYY-MM-DD');
    const phone = `58${values.pagos[field].prefijo}${values.pagos[field].telefono}`;
    const amount = +(values.pagos[field]?.costo ?? 0);

    let payment;

    if (paymentMethod.includes('PAGO MOVIL')) {
      payment = await validateMobilePayment({ date, phone, bankCode, referencia, amount });
    } else if (paymentMethod.includes('TRANSFERENCIA')) {
      payment = await validateTransferPayment({ originBank, date, bankCode, referencia, amount });
    }

    if (payment) {
      return true;
    } else {
      throw Error(errorMessage.PAYMENT_NOT_FOUND);
    }
  } catch (error) {
    console.log(error);
    throw Error(error?.message ?? 'Error al momento de conciliar el pago.');
  }
};

const validateMobilePayment = async ({ date, phone, bankCode, referencia, amount }) => {
  const response = await findPaymentMobile({ date, phone, bank: bankCode });

  if (response?.data?.code === 200) {
    const paymentList = response?.data?.lista;

    const payFiltered = paymentList.filter((f) => [f.NroReferenciaCorto, f.NroReferencia].includes(referencia));

    if (payFiltered.length === 0) {
      throw Error(errorMessage.PAYMENT_REFERENCE_DOES_NOT_MATCH);
    }

    return payFiltered?.find((l) => {
      return validatePaymentDetails(l, { referencia, bankCode, phone, amount, date });
    });
  } else {
    throw Error(errorMessage.COMMUNICATION_ERROR);
  }
};

const validateTransferPayment = async ({ originBank, date, bankCode, referencia, amount }) => {
  let response;
  if (originBank === BANCAMIGA_ID) {
    response = await findInternalInstantCreditPayments();
  } else {
    response = await findInstantCreditPayments(date);
  }

  if (response?.data?.code === 200) {
    const paymentList = response?.data?.data;

    return paymentList?.find((l) => {
      if (originBank === BANCAMIGA_ID) {
        return validateInternalTransfer(l, { referencia, amount, date });
      } else {
        return validateExternalTransfer(l, { referencia, bankCode, amount, date });
      }
    });
  } else {
    throw Error(errorMessage.COMMUNICATION_ERROR);
  }
};

const validateInternalTransfer = (payment, { referencia, amount, date }) => {
  if (payment.reren !== referencia) {
    throw Error(errorMessage.PAYMENT_REFERENCE_DOES_NOT_MATCH);
  }
  if (moment(payment.date).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
    throw Error(errorMessage.DATE_DOES_NOT_MATCH);
  }
  if (+payment.amount !== amount) {
    throw Error(errorMessage.AMOUNT_DOES_NOT_MATCH);
  }
  return true;
};

const validateExternalTransfer = (payment, { referencia, bankCode, amount, date }) => {
  if (payment.Nro_ref_fin !== referencia) {
    throw Error(errorMessage.PAYMENT_REFERENCE_DOES_NOT_MATCH);
  }
  if (payment.Cod_banco_deb !== bankCode) {
    throw Error(errorMessage.BANK_DOES_NOT_MATCH);
  }
  if (moment(payment.Fecha_hora).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
    throw Error(errorMessage.DATE_DOES_NOT_MATCH);
  }
  if (+payment.Monto_txs !== amount) {
    throw Error(errorMessage.AMOUNT_DOES_NOT_MATCH);
  }
  return true;
};

const validatePaymentDetails = (payment, { referencia, bankCode, phone, amount, date }) => {
  if (![payment.NroReferenciaCorto, payment.NroReferencia].includes(referencia)) {
    throw Error(errorMessage.PAYMENT_REFERENCE_DOES_NOT_MATCH);
  }
  if (payment.BancoOrig !== bankCode) {
    throw Error(errorMessage.BANK_DOES_NOT_MATCH);
  }
  if (payment.PhoneOrig !== phone) {
    throw Error(errorMessage.PHONE_DOES_NOT_MATCH);
  }
  if (moment(payment.FechaMovimiento).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD')) {
    throw Error(errorMessage.DATE_DOES_NOT_MATCH);
  }
  if (payment.Amount !== amount) {
    throw Error(errorMessage.AMOUNT_DOES_NOT_MATCH);
  }
  return true;
};

const errorMessage = {
  PAYMENT_NOT_FOUND: 'No se encontraron pagos. Verifique los datos ingresados.',
  COMMUNICATION_ERROR: 'Error de comunicación. Intente más tarde o contacte soporte.',
  PAYMENT_REFERENCE_DOES_NOT_MATCH: 'La referencia bancaria no coincide con el registrado en la transacción.',
  BANK_DOES_NOT_MATCH: 'El banco ingresado no coincide con el registrado en la transacción.',
  AMOUNT_DOES_NOT_MATCH: 'El monto ingresado no coincide con el registrado en la transacción.',
  PHONE_DOES_NOT_MATCH: 'El número de teléfono no coincide con el registrado en la transacción.',
  DATE_DOES_NOT_MATCH: 'La fecha de pago no coincide con el registrado en la transacción.',
};

interface findPaymentMobileDto {
  phone: string;
  bank: string;
  date: string;
}
