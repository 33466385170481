import React, { useState } from 'react';
import { Button, Card, Modal, Col } from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import pdfImg from './../../../assets/images/pdf.png';

const VoucherPreview = ({ comprobantes }) => {
  const [previewVoucher, setPreviewVoucher] = useState({ visible: false, url: '', filename: '' });

  const handlePreview = (url) => {
    setPreviewVoucher({ visible: true, url, filename: getLastItemURL(url) });
  };

  const handleCancel = () => {
    setPreviewVoucher({ visible: false, url: '', filename: '' });
  };

  const getLastItemURL = (url) => url.substring(url.lastIndexOf('/') + 1);

  return (
    <>
      {comprobantes?.length > 0 &&
        comprobantes?.map((comprobante, index) => {
          return (
            <>
              <Col xl={6} lg={8} md={8} sm={8} xs={12} style={{ margin: '10px' }}>
                <Card
                  actions={[
                    <a href={comprobante.url} download={getLastItemURL(comprobante.url)}>
                      Descargar <DownloadOutlined />
                    </a>,
                    <Button
                      type='link'
                      onClick={() => handlePreview(comprobante.url)}
                      style={{ textAlign: 'right', width: '100%' }}
                    >
                      {' '}
                      Preview
                      <EyeOutlined />
                    </Button>,
                  ]}
                  bodyStyle={{ borderTop: '1px solid #00000026' }}
                  hoverable
                  style={{ width: '100%' }}
                  cover={
                    getLastItemURL(comprobante.url).includes('pdf') ? (
                      <img style={{ width: 180, height: 180, padding: '2rem' }} src={pdfImg} alt='file-card' />
                    ) : (
                      <img src={comprobante.url} alt='file-card' />
                    )
                  }
                >
                  {getLastItemURL(comprobante.url)
                    ? getLastItemURL(comprobante.url)
                    : getLastItemURL(comprobante.url).includes('pdf')
                    ? `Pdf`
                    : `Imagen`}
                </Card>
              </Col>
            </>
          );
        })}
      <Modal visible={previewVoucher.visible} title={previewVoucher.filename} footer={null} onCancel={() => handleCancel()}>
        <img alt='file-modal' style={{ width: '100%' }} src={previewVoucher.url} />
      </Modal>
    </>
  );
};

export default VoucherPreview;
