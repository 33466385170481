import { Alert, Col, DatePicker, Divider, Form, Input, InputNumber, Select, Table, Typography } from 'antd';
import { Row } from 'antd/es/grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { Utils } from '../../utils/validators';
import moment from 'moment';
import { ReactComponent as LogoBancamiga } from 'src/assets/images/svg/logo-bancamiga.svg';
import PhoneSelect from '../Forms/components/PhoneSelect';
import { ColumnsType } from 'antd/lib/table';
import { BANCAMIGA_ID } from 'src/services/bancamigaApi';

interface BancamigaTransactionsProps {
  selectedInstitution: sigt.Institucion | undefined;
}

const accountColumns: ColumnsType<any> = [
  {
    title: 'Método de pago',
    dataIndex: 'metodoPago',
    key: 'metodoPago',
    fixed: 'left',
  },
  {
    title: 'Numero de Cuenta / Teléfono',
    dataIndex: 'numeroCuenta',
    key: 'numeroCuenta',
  },
  {
    title: 'Titular',
    dataIndex: 'nombreTitular',
    key: 'nombreTitular',
  },
  {
    title: 'Documento de Identidad',
    dataIndex: 'documento',
    key: 'documento',
  },
];

export default function BancamigaTransactions({ selectedInstitution }: BancamigaTransactionsProps) {
  const [metodoPagoSelected, setMetodoPagoSelected] = useState('');
  const banks = useSelector((state: State) => state.bk.banks);
  const bancamigaData = selectedInstitution?.cuentasBancarias?.find((c) => +c.banco === BANCAMIGA_ID);

  const dataSourceAccount = [
    {
      metodoPago: 'Transferencia',
      ...bancamigaData,
      documento: `${bancamigaData?.rif ? 'RIF: ' : 'Cedula: '}${bancamigaData?.rif || bancamigaData?.cedula}`,
      banco: banks?.find((b) => b.id === bancamigaData?.banco)?.nombre,
    },
    {
      metodoPago: 'Pago móvil',
      documento: `${bancamigaData?.rif ? 'RIF: ' : 'Cedula: '}${bancamigaData?.rif || bancamigaData?.cedula}`,
      banco: banks?.find((b) => b.id === bancamigaData?.banco)?.nombre,
      numeroCuenta: '0412-2468972',
      nombreTitular: bancamigaData?.nombreTitular,
    },
  ];

  useEffect(() => {
    return () => {
      setMetodoPagoSelected('');
    };
  }, []);

  return (
    <Row justify='center' align='middle' gutter={[24, 24]}>
      <Col span={24} style={{ backgroundColor: '#d9d9d9' }}>
        <LogoBancamiga style={{ width: '20rem' }} />
      </Col>
      <Col
        span={24}
        style={{ display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', alignItems: 'start' }}
      >
        <Typography.Title level={4}>Pasos a seguir:</Typography.Title>
        <Typography.Text>
          1.- Haga una transferencia validad por el monto requerido a las cuentas de bancamiga de la alcaldía. mostrados a
          continuación
        </Typography.Text>

        <div style={{ width: '100%' }}>
          <Table pagination={false} dataSource={dataSourceAccount} columns={accountColumns} />
        </div>

        <Typography.Text>
          2.- Seleccione el método de pago a utilizar e ingrese el banco de origen, número de referencia valido, fecha de pago y
          teléfono en caso de seleccionar pago móvil.
        </Typography.Text>

        <Alert
          type='warning'
          style={{ width: '100%' }}
          message={
            <Typography.Text strong>
              Nota: En el caso de hacer transferencia bancaria la misma sera procesada si se ha realizado en el día hábil actual.
            </Typography.Text>
          }
        />

        <Typography.Text>
          3.- Una vez realizada la transferencia e ingresado los datos del pago puede procesar el pago.
        </Typography.Text>
      </Col>
      <Divider />
      <Col span={24}>
        <Form.Item required fieldKey={0} style={{ width: '100%', margin: 0 }}>
          <Row gutter={24} style={{ padding: '0 20px' }}>
            <Col xl={8} xs={24}>
              <Form.Item
                label='Método de Pago'
                name={['pagos', 0, 'metodoPago']}
                rules={[{ required: true, message: 'Debe indicar el método de pago' }]}
              >
                <Select placeholder='Método de Pago' onChange={(e: string) => setMetodoPagoSelected(e)}>
                  <Select.Option value='TRANSFERENCIA BOTON BANCAMIGA'>Transferencia - botón bancamiga</Select.Option>
                  <Select.Option value='PAGO MOVIL BOTON BANCAMIGA'>Pago móvil - botón bancamiga</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} xl={8}>
              <Form.Item
                label='Banco de Origen'
                name={['pagos', 0, 'banco']}
                rules={[{ required: true, message: 'Debe indicar el banco' }]}
              >
                {banks ? (
                  <Select placeholder='Banco de Origen'>
                    {banks?.map((b, i) => (
                      <Select.Option key={`b-${b.id}`} value={b.id}>
                        {b.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <div></div>
                )}
              </Form.Item>
            </Col>

            {metodoPagoSelected.includes('PAGO MOVIL') && (
              <Col xl={8} xs={24}>
                <Form.Item
                  label='Teléfono'
                  rules={[{ required: true, message: 'Por favor ingrese el teléfono del titular' }]}
                  name={['pagos', 0, 'telefono']}
                >
                  <Input
                    addonBefore={<PhoneSelect name={['pagos', 0, 'prefijo']} />}
                    placeholder='Teléfono'
                    maxLength={7}
                    minLength={7}
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24} xl={8}>
              <Form.Item
                label='Numero de Referencia'
                name={['pagos', 0, 'referencia']}
                rules={[
                  { required: true, message: 'Por favor ingrese el numero de referencia.' },
                  { validator: Utils.validators.alphaNumeric },
                ]}
              >
                <Input placeholder='Referencia' />
              </Form.Item>
            </Col>

            <Col xs={24} xl={8}>
              <Form.Item
                label='Fecha de Pago'
                name={['pagos', 0, 'fecha']}
                rules={[{ required: true, message: 'Por favor ingrese la fecha de pago.' }]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD-MM-YYYY'}
                  disabledDate={(current) => current > moment().endOf('day')}
                />
              </Form.Item>
            </Col>
            <Col xs={24} xl={6}>
              <Form.Item
                label='Monto'
                name={['pagos', 0, 'costo']}
                rules={[{ required: true, message: 'Por favor ingrese el monto del pago.' }]}
              >
                <InputNumber
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => (value ? value.replace(/Bs\s?|(,*)/g, '') : '')}
                  min={0}
                  step={1}
                  style={{ width: '100%' }}
                  placeholder='Monto'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  );
}
