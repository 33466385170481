import React, { useState, useEffect } from 'react';
import { States, State } from 'sigt';
import { connect, useSelector } from 'react-redux';
import { Card, Row, Col, Form, Input, Select, Table, Tag, Button, message, Pagination, Alert, Typography } from 'antd';
import usePagination, { useWindowDimensions } from '../../utils/hooks';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { SearchOutlined, CreditCardFilled, EyeFilled } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router';
import { setRequestsPagination } from '../../redux/actions/requests';
import Petro from '../Icons/Petro';
import { roundCalc } from './PaySpecificTax';
import TaxpayerInfoSheet from '../Taxpayer/TaxpayerInfoSheet';
const server = process.env.REACT_APP_SERVER_URL;

const PendingRequests: React.FC<PendingRequestsProps> = ({ thm, auth, setReduxRequests, reduxReqs, reduxReqsPagination }) => {
  const [tipoDocumento, setTipoDocumento] = useState('V');
  const [searching, setSearching] = useState(false);
  const [pageTotal, setPageTotal] = useState(reduxReqsPagination?.total ?? 0);
  const [taxpayerInfo, setTaxpayerInfo] = useState<any>(null);
  const [paginatedData, currentPage, setCurrentPage, pageSize] = usePagination(reduxReqs);

  const petro = useSelector((state: State) => state.coin.petro);

  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue({
      rim: reduxReqsPagination?.referencia,
      doc: reduxReqsPagination?.documento,
      tipoContribuyente: reduxReqsPagination?.tipoContribuyente,
    });
    setTipoDocumento(reduxReqsPagination?.tipoDocumento ?? 'V');
  }, []);

  const canPay: number[] = [22, 25, 40, 41, 53];

  const formatCurrency = (number: number) => new Intl.NumberFormat('de-DE').format(number);

  const tipoAddon = (
    <Select defaultValue='V' value={tipoDocumento} onChange={(e) => setTipoDocumento(e)}>
      <Select.Option value='V'>V</Select.Option>
      <Select.Option value='E'>E</Select.Option>
      <Select.Option value='J'>J</Select.Option>
      <Select.Option value='G'>G</Select.Option>
      <Select.Option value='P'>P</Select.Option>
    </Select>
  );

  const getState = (estado) => {
    if (estado === 'ingresardatos') return { name: 'En espera de pago', color: 'cyan' };
    else if (estado === 'validando') return { name: 'Validando pago', color: 'warning' };
    else return { name: 'Solvente', color: 'green' };
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Id Solicitud',
      dataIndex: 'id',
      align: 'center',
      render: (id, r) => <span>{id}</span>,
    },
    {
      title: 'Documento de Identidad',
      align: 'center',
      dataIndex: ['contribuyente', 'documento'],
      render: (doc, r) => (
        <span>
          {r.contribuyente.tipoDocumento}-{doc}
        </span>
      ),
    },
    {
      title: 'R.I.M.',
      dataIndex: 'referenciaMunicipal',
      render: (rim) => <span>{rim ? rim : 'N/A'}</span>,
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      render: (tipo) => <span>{tipo ? tipo : 'N/A'}</span>,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      render: (fecha) => (fecha ? <span>{moment(fecha).utc().format('DD/MM/YYYY')}</span> : 'Sin Fecha'),
    },
    {
      title: (
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Typography.Text>Monto Base (MMV)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: 'red', fontSize: 10 }}>sin intereses, recargos o multas</Typography.Text>
          </Col>
        </Row>
      ),
      align: 'center',
      dataIndex: 'montoPetroOriginal',
      render: (_petro, record) =>
        _petro ? (
          <span>
            {Number(
              Number(_petro ?? 0) +
                Number(record?.interesesAE?.totalInteres ?? 0) / petro +
                Number(record?.recargoAE?.totalRecargo ?? 0) / petro
            ).toFixed(8)}{' '}
            <Petro />
          </span>
        ) : (
          '-'
        ),
    },
    {
      title: (
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Typography.Text>Monto Base (Bs.)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: 'red', fontSize: 10 }}>sin intereses, recargos o multas</Typography.Text>
          </Col>
        </Row>
      ),
      dataIndex: 'montoOriginal',
      align: 'center',
      render: (monto, record) => (
        <span>{`Bs. ${formatCurrency(
          roundCalc(
            (+monto || +record.montoPetroOriginal * petro) +
              Number(record?.interesesAE?.totalInteres ?? 0) +
              Number(record?.recargoAE?.totalRecargo ?? 0)
          )
        )}`}</span>
      ),
    },
    {
      title: (
        <Row justify='center' align='middle'>
          <Col span={24}>
            <Typography.Text>Monto con Descuento (Bs.)</Typography.Text>
          </Col>
          <Col span={24}>
            <Typography.Text style={{ color: 'red', fontSize: 10 }}>sin intereses, recargos o multas</Typography.Text>
          </Col>
        </Row>
      ),
      dataIndex: 'monto',
      align: 'center',
      render: (monto, record) => (
        <span>{`Bs. ${formatCurrency(
          roundCalc(
            (+monto || +record.montoPetro * petro) +
              Number(record?.interesesAE?.totalInteres ?? 0) +
              Number(record?.recargoAE?.totalRecargo ?? 0)
          )
        )}`}</span>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'monto',
      render: (_, record) => (
        <Tag style={{ width: '100%', textAlign: 'center' }} color={getState(record.estado).color}>
          {getState(record.estado).name}
        </Tag>
      ),
    },
  ];

  columns.push({
    title: 'Acciones',
    dataIndex: 'id',
    render: (id, tax) => {
      return (
        <Button
          icon={
            (auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
            tax.estado === 'ingresardatos' ? (
              <CreditCardFilled />
            ) : (
              <EyeFilled />
            )
          }
          type='primary'
          onClick={() => history.push(`/dashboard/impuestos/pagar/${id}`, { solicitudes: reduxReqs })}
        >
          {(auth.user?.tipoUsuario === 4 || canPay.includes(auth.user?.institucion?.cargo?.id || 0)) &&
          tax.estado === 'ingresardatos'
            ? 'Pagar'
            : 'Ver'}
        </Button>
      );
    },
  });

  const search = async (page = 1) => {
    const values = await form.validateFields();
    try {
      setSearching(true);
      const response = await axios.get(
        `${server}/settlements/instances/${values.tipoContribuyente}?documento=${values.doc}&tipoDocumento=${tipoDocumento}${
          values.rim ? `&referencia=${values.rim}` : ''
        }&page=${page}`,
        { headers: { Authorization: `Bearer ${auth.token}` } }
      );
      // setCurrentPage(page);
      setTaxpayerInfo(response.data.contribuyente);
      setPageTotal(response.data.totalSolicitudes);
      setReduxRequests(response.data.solicitudes, {
        total: response.data.totalSolicitudes,
        tipoContribuyente: values.tipoContribuyente,
        documento: values.doc,
        tipoDocumento,
        referencia: values.rim,
        currentPage: page,
      });
    } catch (e) {
      // setCurrentPage(0);
      setPageTotal(0);
      setReduxRequests([], {
        total: 0,
        tipoContribuyente: values.tipoContribuyente,
        documento: values.doc,
        tipoDocumento,
        referencia: values.rim,
        currentPage: 1,
      });
      message.error(e?.response?.data?.message || 'Error al buscar contribuyente');
    } finally {
      setSearching(false);
    }
  };

  return (
    <Card
      style={{ height: '100%' }}
      title='Liquidaciones Pendientes'
      bodyStyle={{ height: 'calc(100% - 88px)', overflowY: 'scroll', overflowX: 'hidden', border: '1px solid #f0f0f0' }}
      headStyle={{ height: 64, backgroundColor: thm.primaryColor, padding: width < 992 ? '0 10px' : '0 20px', color: 'white' }}
    >
      <Form layout='vertical' form={form}>
        <Row gutter={[24, 24]}>
          <Col xl={7} xs={24}>
            <Form.Item
              label='Tipo de Contribuyente'
              name='tipoContribuyente'
              rules={[{ required: true, message: 'Debe indicar el tipo de contribuyente' }]}
            >
              <Select placeholder='Tipo de Contribuyente'>
                <Select.Option value='NATURAL'>Natural</Select.Option>
                <Select.Option value='JURIDICO'>Jurídico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item
              label='Documento de Identidad'
              name='doc'
              rules={[{ required: true, message: 'Debe ingresar el documento de identidad' }]}
            >
              <Input placeholder='Documento de Identidad' addonBefore={tipoAddon} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={7}>
            <Form.Item label='R.I.M.' name='rim'>
              <Input placeholder='R.I.M.' />
            </Form.Item>
          </Col>
          <Col xl={3} xs={12}>
            <Button
              onClick={() => search()}
              style={{ marginTop: width < 1200 ? 0 : 38, width: '100%' }}
              loading={searching}
              icon={<SearchOutlined />}
              type='primary'
            >
              Buscar
            </Button>
          </Col>
          <Col span={24} style={{ marginBottom: 38 }}>
            <TaxpayerInfoSheet taxpayerInfo={taxpayerInfo} loading={searching} />
          </Col>
          <Col span={24}>
            <Alert
              message={
                'Para obtener información precisa sobre su solicitud, es necesario que ingrese al detalle de la misma. Allí podrá verificar si existen multas, intereses o recargos asociados. No olvide revisar esta sección para evitar inconvenientes.'
              }
              type='error'
            />
          </Col>
          <Col span={24}>
            <Table
              style={{ marginTop: width < 1200 ? 38 : 0 }}
              columns={columns}
              dataSource={reduxReqs}
              rowKey='id'
              bordered
              loading={searching}
              pagination={false}
            />
          </Col>
          <Col span={24}>
            <Pagination
              key={'pagination'}
              current={reduxReqsPagination?.currentPage}
              onChange={(page) => search(page)}
              pageSize={pageSize}
              total={pageTotal}
              size='small'
            />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: State) => ({
  thm: state.thm,
  auth: state.auth,
  reduxReqs: state.req.requests,
  reduxReqsPagination: state.req.pagination,
});

export default connect(mapStateToProps, { setReduxRequests: setRequestsPagination })(PendingRequests);

interface PendingRequestsProps {
  thm: States.ThemeColors;
  auth: States.Auth;
  setReduxRequests: any;
  reduxReqs: States.Requests['requests'];
  reduxReqsPagination: States.Requests['pagination'];
}
