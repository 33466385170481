import { Button, Col, Row, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'sigt';
import { ReactComponent as LogoBancamiga } from 'src/assets/images/svg/logo-bancamiga.svg';
import { ReactComponent as IconPagoTradicional } from 'src/assets/images/svg/pago-tradicional.svg';

export type TransactionType = 'NO_SELECTED' | 'TRADITIONAL' | 'BANCAMIGA';

interface PaymentTransaction {
  children: {
    traditional: React.ReactNode;
    bancamiga: React.ReactNode;
  };
  descriptionPayment: React.ReactNode;
  form: FormInstance;
  onSelect: Function;
}

const defaultBtnStyle: React.CSSProperties = {
  width: '16rem',
  height: '6rem',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem',
};

export default function PaymentTransaction({
  children,
  descriptionPayment: DescriptionPayment,
  form,
  onSelect,
}: PaymentTransaction) {
  const [transactionType, setTransactionType] = useState<TransactionType>('NO_SELECTED');
  const auth = useSelector((state: State) => state.auth);

  const handleSelect = async (type: TransactionType) => {
    setTransactionType(type);
    onSelect(type);
    if (form) {
      form?.setFieldsValue({
        pagos: [],
      });
    }
  };

  return (
    <>
      <Row justify='start' align='middle' gutter={[24, 24]}>
        <Col>
          <Button style={{ ...defaultBtnStyle }} onClick={() => handleSelect('BANCAMIGA')}>
            <LogoBancamiga />
            <Typography.Text>{auth.user?.tipoUsuario === 4 ? 'Pagar con bancamiga' : 'Procesar con bancamiga'}</Typography.Text>
          </Button>
        </Col>
        <Col>
          <Button style={{ ...defaultBtnStyle, flexDirection: 'row' }} onClick={() => handleSelect('TRADITIONAL')}>
            <IconPagoTradicional style={{ flex: 1 }} width={'4rem'} height={'4rem'} />
            <Typography.Text style={{ whiteSpace: 'break-spaces', width: '0.5rem', flex: 1.5 }}>
              {auth.user?.tipoUsuario === 4 ? 'Cargar transferencia bancaria' : 'Procesar con método tradicional'}
            </Typography.Text>
          </Button>
        </Col>
      </Row>
      <Row justify='center' align='middle' gutter={[24, 24]}>
        <Col span={24}>{DescriptionPayment}</Col>
        {transactionType === 'TRADITIONAL' && <Col span={24}>{children.traditional}</Col>}
        {transactionType === 'BANCAMIGA' && <Col span={24}>{children?.bancamiga}</Col>}
      </Row>
    </>
  );
}
